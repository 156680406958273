
body {
    background-color: white;
}
.white {
    color: white;
    text-decoration: none;
}
.remove {color: red !important;}

// customize some Bootstrap variables
$primary: darken(#428bca, 20%);

// the ~ allows you to reference things in node_modules
@import "~bootstrap/scss/bootstrap";
@import 'tom-select/dist/css/tom-select.bootstrap5.css';

